import React from "react";
import Select from "react-select";
import { getLanguageObject } from "../../../../../utils/language";
import useBusinessInfo from "./hooks/useBusinessInfo";
import ImageUploader from "../../../../../components/imageUploader/ImageUploader";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";

const BusinessInfo = ({ business, onBusinessUpdated }) => {
  const {
    values,
    types,
    handleChangeInput,
    handleSubmitInfo,
    shouldSubmitInfo,
    toggleInfoSubmit,

    amenities,
    shouldSubmitAmenities,
    toggleAmenitiesSubmit,
    handleSubmitAmenities,

    businessLogoList,
  } = useBusinessInfo({
    business,
    onBusinessUpdated,
  });

  return (
    <>
      <div className="p-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.about.title}
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.name}
                </label>
                <input
                  type="text"
                  disabled={!shouldSubmitInfo}
                  placeholder="Name"
                  className="form-control"
                  onChange={(e) => handleChangeInput("name", e.target.value)}
                  value={values.name}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.description}
                </label>
                <input
                  type="text"
                  disabled={!shouldSubmitInfo}
                  placeholder="Description"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeInput("description", e.target.value)
                  }
                  value={values.description}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.type}
                </label>
                <Select
                  placeholder={
                    getLanguageObject().pages.business_profile.about.select_type
                  }
                  isDisabled={!shouldSubmitInfo}
                  isMulti
                  options={types}
                  isSearchable={false}
                  onChange={(e) => handleChangeInput("types", e)}
                  value={values.types}
                  className="custom-react-select"
                />
              </div>

              <div className="form-group mb-3 col-md-6">
                <label className="form-label">
                  {getLanguageObject().pages.business_profile.about.logo}
                </label>
                <ImageUploader
                  business={business}
                  onFilesUploaded={(images) => {
                    handleChangeInput("logo", images[0].location);
                  }}
                  fileList={businessLogoList}
                  cropSize={{ width: 100, height: 100 }}
                  isDisabled={!shouldSubmitInfo}
                  multiple={false}
                >
                  <button>
                    <AvatarIcon />
                  </button>
                </ImageUploader>
              </div>
            </div>

            {shouldSubmitInfo ? (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubmitInfo}
              >
                {getLanguageObject().pages.common.form.submit_button}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => toggleInfoSubmit(true)}
              >
                {getLanguageObject().pages.common.form.edit_button}
              </button>
            )}
          </form>
        </div>
      </div>

      <hr />

      <div className="p-3 mt-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.amenities.title}
          </h4>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group mb-3 col-md-8">
                <label className="form-label">
                  {
                    getLanguageObject().pages.business_profile.amenities
                      .business_amenities
                  }
                </label>
                <Select
                  placeholder={
                    getLanguageObject().pages.business_profile.amenities
                      .select_amenities
                  }
                  isDisabled={!shouldSubmitAmenities}
                  isMulti
                  options={amenities}
                  isSearchable={false}
                  onChange={(e) => handleChangeInput("amenities", e)}
                  value={values.amenities}
                  className="custom-react-select"
                />
              </div>
            </div>

            {shouldSubmitAmenities ? (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubmitAmenities}
              >
                {getLanguageObject().pages.common.form.submit_button}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                onClick={() => toggleAmenitiesSubmit(true)}
              >
                {getLanguageObject().pages.common.form.edit_button}
              </button>
            )}
          </form>
        </div>
      </div>

      <hr />
      <div className="profile-personal-info p-3 mt-3">
        <h4 className="text-primary mb-4">
          {getLanguageObject().pages.business_profile.info.title}
        </h4>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.address}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <span>{business?.info?.address || "-"}</span>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <h5 className="f-w-500">
              {getLanguageObject().pages.business_profile.info.employees}
              <span className="pull-right">:</span>
            </h5>
          </div>
          <div className="col-9">
            <p>{business?.employees?.length}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessInfo;
