import { PLATFORM } from "../../../constant/global";

const usePlatformSelection = ({ values, onSelectPlatform }) => {
  const handleChangeInput = (value) => {
    let updatedPlatforms =
      values.platform === PLATFORM.HYBRID
        ? [PLATFORM.ONLINE, PLATFORM.IN_PERSON]
        : values.platform.split(",");

    if (value === PLATFORM.HYBRID) {
      updatedPlatforms = [PLATFORM.ONLINE, PLATFORM.IN_PERSON];
    } else {
      if (updatedPlatforms.includes(value)) {
        updatedPlatforms = updatedPlatforms.filter((item) => item !== value);
      } else {
        updatedPlatforms.push(value);
      }
    }

    let platformValue = "";
    if (
      updatedPlatforms.includes(PLATFORM.ONLINE) &&
      updatedPlatforms.includes(PLATFORM.IN_PERSON)
    ) {
      platformValue = PLATFORM.HYBRID;
    } else {
      platformValue = updatedPlatforms[0] || PLATFORM.IN_PERSON;
    }

    onSelectPlatform(platformValue);
  };

  return { handleChangeInput };
};

export default usePlatformSelection;
