import { useState } from "react";
import { handleUpdateBusinessInfo } from "../../../actions/business";

const useBusinessImages = ({ business, onBusinessUpdated }) => {
  const businessImages = business?.info?.images || [];

  const [selectedImages, setSelectedImages] = useState([]);

  const combinedImages = [...businessImages, ...selectedImages];

  const handleUpdateBusinessImages = (images) => {
    const variables = {
      images,
    };

    handleUpdateBusinessInfo({
      variables,
      onSuccess: onBusinessUpdated,
      onFailure: () => {},
    });
  };

  const handleSubmit = () => {
    handleUpdateBusinessImages(combinedImages);
  };

  const handleChangeImages = (files = []) => {
    const fileLocations = files.map((file) => file.location);

    setSelectedImages((prevState) => [...prevState, ...fileLocations]);
  };

  const handleRemoveImage = (removedImage) => {
    const images = businessImages.filter((image) => image !== removedImage);

    handleUpdateBusinessImages(images);
  };

  return {
    handleSubmit,
    businessImages,
    selectedImages,
    handleChangeImages,
    handleRemoveImage,
  };
};
export default useBusinessImages;
