export const getDefaultBusinessLink = () => ({
  name: { label: "", value: "" },
  url: "",
  id: "",
});

export const LINK_TAGS = [
  { value: "facebook", format: "https://facebook.com/" },
  { value: "youtube", format: "https://youtube.com/" },
  { value: "whatsapp", format: "https://whatsapp.com/" },
  { value: "instagram", format: "https://instagram.com/" },
  { value: "tiktok", format: "https://tiktok.com/" },
  { value: "wechat", format: "https://wechat.com/" },
  { value: "telegram", format: "https://telegram.com/" },
  { value: "snapchat", format: "https://snapchat.com/" },
  { value: "reddit", format: "https://reddit.com/" },
  { value: "website", format: "https://website.com" },
];
