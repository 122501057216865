import { useData } from "../../../../hooks/useData";

const useBusinessProfile = () => {
  const [business = null, isLoading, hasError, getBusiness] = useData({
    endpoint: "/",
  });

  const getBusinessAdminUser = (business) => {
    if (business) {
      for (const item of business?.employees) {
        if (item?.user?.role === "BUSINESS_ADMIN") {
          return item?.user;
        }
      }
      return null;
    }
  };

  const handleGetBusiness = () => {
    getBusiness();
  };

  const isEmpty = !business;

  const businessAdminUser = getBusinessAdminUser(business);

  return {
    business,
    isLoading,
    hasError,
    isEmpty,
    getBusiness,
    businessAdminUser,
    handleGetBusiness,
  };
};

export default useBusinessProfile;
