import { BASE_URL } from "../../../../services/AxiosInstance";
import { getAuthorizationHeader } from "../../../utils/request";

export const uploadFiles = async ({ files = [], businessId }) => {
  const fileUploadUrl = `${BASE_URL}/upload?platform=business&externalId=${businessId}`;
  const fileUploadHeaders = getAuthorizationHeader();

  const uploadPromises = files.map((file) => {
    const formData = new FormData();

    formData.append("file", file);

    return fetch(fileUploadUrl, {
      method: "POST",
      body: formData,
      headers: fileUploadHeaders,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(`Upload failed for file: ${file.name}`);
        }
      })
      .then((response) => {
        console.log(`Upload successful for file: ${response.originalname}`);

        return response;
      })
      .catch((error) => {
        console.log(`Error uploading file: ${file.name}`, error);
      });
  });

  return await Promise.all(uploadPromises);
};
