import { getLanguageObject } from "../../../../utils/language";

export const FEE_COLUMNS = [
  {
    heading: getLanguageObject().pages.fees.list.payment_id,
  },
  {
    heading: getLanguageObject().pages.fees.list.status,
  },
  {
    heading: getLanguageObject().pages.fees.list.created_at,
  },
  {
    heading: getLanguageObject().pages.fees.list.updated_at,
  },
];
