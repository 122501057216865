import {
  getDefaultBusinessLink,
  LINK_TAGS,
} from "../constants/business-links.constants";
import { capitalize } from "../../../../../../utils/string";

export const getBusinessLinks = (links = []) => {
  return links.length
    ? links.map((link) => {
        return {
          name: { label: link.name, value: link.name },
          url: link.url,
          id: link.id,
        };
      })
    : [getDefaultBusinessLink()];
};

export const getFormattedTags = () => {
  return LINK_TAGS.map((tag) => {
    return {
      label: capitalize(tag.value),
      value: tag.value,
      format: tag.format,
    };
  });
};
