import React from "react";
import { getFormattedDate } from "../../utils/date";
import { useData } from "../../hooks/useData";

const LastUsages = () => {
  const [lastUsages = [], isLoading, hasError] = useData({
    endpoint: "purchase/usages",
  });

  const columns = [
    { heading: "Servis" },
    { heading: "Müşteri" },
    { heading: "Kullanım tarihi" },
  ];

  const lastThreeUsages = lastUsages.slice(0, 3);

  return (
    <div>
      <table id="example4" className="display dataTable no-footer w-100">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lastThreeUsages.map((service, index) => (
            <tr key={index}>
              <td>{service.title}</td>
              <td>{service.userProfile.firstName}</td>
              <td>{getFormattedDate(service.usedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LastUsages;
