import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { LOCAL_STORAGE_KEY_CONSTANTS } from "../../constant/global";

export const LanguageSwitcher = () => {
  const { changeLanguage } = useContext(LanguageContext);

  const handleChangeLanguage = (language) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY_CONSTANTS.FORBODY_BUSINESS_LANGUAGE_KEY,
      language,
    );
    changeLanguage();
    window.location.reload();
  };

  return (
    <>
      <div className="d-flex justify-content-start gap-2 px-4 py-1">
        <button
          onClick={() => handleChangeLanguage("en")}
          className="p-2 text-black border-0 rounded"
        >
          EN
        </button>
        <button
          onClick={() => handleChangeLanguage("tr")}
          className="p-2 text-black border-0 rounded"
        >
          TR
        </button>
      </div>
    </>
  );
};
