import React, { useContext } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ThemeContext } from "../../../../context/ThemeContext";
import useSidebar from "../hooks/useSidebar";

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const {
    handleMenuActive,
    MenuList,
    date,
    hideOnScroll,
    handleSubmenuActive,
    state,
    path,
  } = useSidebar();

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li
                  className={`nav-label ${menuClass} ${data.extraclass}`}
                  key={index}
                >
                  {data.title}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${state.active === data.title ? "mm-active" : ""}${data.to === path ? "mm-active" : ""}`}
                  key={index}
                >
                  {data.title && data.title.length > 0 ? (
                    <>
                      <Link
                        to={data.to}
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                        <span className="badge badge-xs style-1 badge-danger">
                          {data.update}
                        </span>
                      </Link>
                      <Collapse /* in={state.active === data.title ? true : false} */
                      >
                        <ul
                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                        >
                          <li
                            key={index}
                            className={`${state.activeSubmenu === data.title ? "mm-active" : ""}${data.to === path ? "mm-active" : ""}`}
                          >
                            {data.content && data.content.length > 0 ? (
                              <>
                                <Link
                                  to={data.to}
                                  className={data.hasMenu ? "has-arrow" : ""}
                                  onClick={() => {
                                    handleSubmenuActive(data.title);
                                  }}
                                >
                                  {data.title}
                                </Link>
                                <Collapse
                                  in={
                                    state.activeSubmenu === data.title
                                      ? true
                                      : false
                                  }
                                >
                                  <ul
                                    className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                                  >
                                    {data.content &&
                                      data.content.map((data, index) => {
                                        return (
                                          <li key={index}>
                                            <Link
                                              className={`${path === data.to ? "mm-active" : ""}`}
                                              to={data.to}
                                            >
                                              {data.title}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </Collapse>
                              </>
                            ) : (
                              <Link
                                to={data.to}
                                className={`${data.to === path ? "mm-active" : ""}`}
                              >
                                {data.title}
                              </Link>
                            )}
                          </li>
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link
                      to={data.to}
                      className={`${data.to === path ? "mm-active" : ""}`}
                    >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
        <div className="copyright">
          <p>forBody Admin © {date.getFullYear()} All Rights Reserved</p>
          <p className="fs-12">
            Made with{" "}
            <span
              className="heart"
              onClick={(e) => e.target.classList.toggle("heart-blast")}
            ></span>{" "}
            by Netta Software
          </p>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
