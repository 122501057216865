import { PLATFORM } from "../constant/global";
import { getLanguageObject } from "./language";

export const getFormattedServicePlatform = (platform) => {
  switch (platform) {
    case PLATFORM.HYBRID:
      return getLanguageObject().pages.service.platform.hybrid;

    case PLATFORM.IN_PERSON:
      return getLanguageObject().pages.service.platform.in_person;

    case PLATFORM.ONLINE:
      return getLanguageObject().pages.service.platform.online;

    default:
      return "";
  }
};
