import React from "react";
import { getLanguageObject } from "../../../../../utils/language";
import Select from "react-select";
import { IconButton } from "rsuite";
import MinusIcon from "@rsuite/icons/Minus";

import useBusinessLinks from "./hooks/useBusinessLinks";

const BusinessLinks = ({ business, onBusinessUpdated }) => {
  const {
    businessLinks,
    linkNames,
    showCreateButton,
    handleAddBusinessLink,
    handleChangeBusinessLink,
    handleCreateBusinessLink,
    handleDeleteBusinessLink,
  } = useBusinessLinks({ business, onBusinessUpdated });

  return (
    <div className="p-3">
      <div className="settings-form">
        <h4 className="text-primary">
          {getLanguageObject().pages.business_profile.links.title}
        </h4>
      </div>
      <div className="col-12">
        {businessLinks.map((link, index) => (
          <div style={{ flexGrow: 1, mt: 1 }} key={index}>
            <div className="row">
              <div className="col-3 mb-3">
                <div>
                  <label
                    id="demo-simple-select-label"
                    style={{ width: "max-content" }}
                  >
                    {getLanguageObject().pages.business_profile.info.link_name}
                  </label>
                  <Select
                    options={linkNames}
                    isMulti={false}
                    name="name"
                    isDisabled={!!link.id}
                    labelId="demo-simple-select-label"
                    value={link.name}
                    label="name"
                    onChange={(value) =>
                      handleChangeBusinessLink(link.id, "name", value)
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                <div>
                  <label htmlFor="outlined-adornment-socialMediaLinks">
                    {getLanguageObject().pages.business_profile.info.url}
                  </label>
                  <input
                    id="outlined-adornment-socialMediaLinks"
                    type="text"
                    className="form-control"
                    value={link.url}
                    disabled={!!link.id}
                    name="url"
                    onChange={(event) =>
                      handleChangeBusinessLink(
                        link.id,
                        "url",
                        event.target.value,
                      )
                    }
                    label="Url"
                  />
                </div>
              </div>
              <div className="col-3">
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    marginTop: 20,
                  }}
                >
                  <div>
                    <IconButton
                      icon={<MinusIcon />}
                      appearance="default"
                      onClick={() => handleDeleteBusinessLink(link.id)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {showCreateButton ? (
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleCreateBusinessLink}
          >
            {getLanguageObject().pages.common.form.submit_button}
          </button>
        ) : (
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleAddBusinessLink}
          >
            {getLanguageObject().pages.common.form.add_new_button}
          </button>
        )}
      </div>
    </div>
  );
};

export default BusinessLinks;
