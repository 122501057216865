import React from "react";
import { Col, Row } from "react-bootstrap";

import { ProgressCard } from "../../../elements/CardDesign";
import IncomeExpense from "../../../elements/dashboard/BusinessRevenue";
import LastUsages from "../../../elements/dashboard/LastUsages";
import ReportCards from "../../../elements/dashboard/ReportCards";

const Dashboard = () => {
  return (
    <>
      <Row>
        <Col xl={"12"} xxl={"12"} sm={"12"}>
          <Row>
            <ReportCards />
          </Row>
        </Col>
        <Col xl={"6"} xxl={"6"} sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Kullanıcı / Kazanç</h3>
            </div>
            <div className="card-body">
              <IncomeExpense />
            </div>
          </div>
        </Col>
        <Col xl={"6"} xxl={"6"} sm={"6"}>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Son Kullanımlar</h3>
            </div>
            <div className="card-body">
              <LastUsages />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
