import React from "react";
import { getLanguageObject } from "../../../../../utils/language";
import useBusinessLocation from "./hooks/useBusinessLocation";
import LocationInput from "./components/LocationInput/LocationInput";

const BusinessLocation = ({ business, onBusinessUpdated }) => {
  const { values, handleSubmit, handleChangeInput } = useBusinessLocation({
    business,
    onBusinessUpdated,
  });

  return (
    <div className="form-group col-md-8">
      <div className="p-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.location.title}
          </h4>
          <LocationInput
            onSelectLocation={(location) => {
              handleChangeInput(location);
            }}
            defaultLocation={values}
          />
        </div>
        <div style={{ padding: "10px" }}>
          <button
            className="btn btn-primary"
            type="submit"
            onClick={() => handleSubmit()}
          >
            {getLanguageObject().pages.common.form.submit_button}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessLocation;
