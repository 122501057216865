import { getLanguageObject } from "../../../../utils/language";

export const EMPLOYEE_COLUMNS = [
  {
    heading: getLanguageObject().pages.employees.list.email,
  },
  {
    heading: getLanguageObject().pages.employees.list.firstName,
  },
  {
    heading: getLanguageObject().pages.employees.list.lastName,
  },
  {
    heading: getLanguageObject().pages.employees.list.phone,
  },
  {
    heading: getLanguageObject().pages.employees.list.createdAt,
  },
];
