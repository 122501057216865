import React from "react";
import { Row } from "react-bootstrap";
import useMemberships from "./hooks/useMemberships";
import SubmitMembership from "./components/SubmitMembership/SubmitMembership";
import { getLanguageObject } from "../../../utils/language";

const Memberships = () => {
  const {
    Content,
    handleOpenSubmitMembershipModal,
    submitMembershipModalProps,
  } = useMemberships();

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {getLanguageObject().pages.common.menu.memberships}
          </h4>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleOpenSubmitMembershipModal();
            }}
          >
            {getLanguageObject().pages.memberships.add_membership_button}
          </button>
        </div>
        <div className="card-body">
          <Row>{Content}</Row>
        </div>
        <SubmitMembership {...submitMembershipModalProps} />
      </div>
    </>
  );
};

export default Memberships;
