import { useData } from "../../hooks/useData";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";

const useRouter = () => {
  const { updateAppBusiness } = useContext(AppContext);
  const [business = {}, isLoading, hasError, getBusiness] = useData({
    endpoint: "/",
  });

  useEffect(() => {
    getBusiness();
  }, []);

  useEffect(() => {
    if (business.id) {
      updateAppBusiness(business);
    }
  }, [business.id]);

  return { business, isLoading, hasError };
};

export default useRouter;
