import React from "react";
import { Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { LEVELS } from "../../../../constant/global";
import { getLanguageObject } from "../../../../utils/language";
import { getFormattedServicePlatform } from "../../../../utils/service-platform";
import { formatCurrency } from "../../../../utils/number";

const MembershipCard = ({
  membership,
  handleDeleteMembership,
  handleOpenSubmitMembershipModal,
}) => {
  return (
    <Col xl={3} xxl={4} lg={4} md={6} sm={6}>
      <Card>
        <div className="card-body">
          <h4>{membership.name}</h4>
          <ul className="list-group mb-3 list-group-flush">
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.memberships.list.description} :
              </span>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={1}>{membership.description}</Tooltip>}
              >
                <strong
                  className="d-inline-block text-nowrap overflow-hidden text-truncate cursor-pointer"
                  style={{ maxWidth: "150px" }}
                >
                  {membership.description}
                </strong>
              </OverlayTrigger>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.memberships.list.duration} :
              </span>
              <strong>
                {membership.duration}
                {getLanguageObject().pages.common.form.month}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.memberships.list.level} :
              </span>
              <strong>
                {membership.level ? LEVELS[membership.level] : "-"}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span>
                <i className="fa fa-money-bill text-primary me-2" />
                {getLanguageObject().pages.memberships.list.price}
              </span>
              <strong>{formatCurrency(membership.price)}</strong>
            </li>

            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.service.platform.title} :
              </span>
              <strong>
                {getFormattedServicePlatform(membership.platform) || "-"}
              </strong>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleOpenSubmitMembershipModal}
            >
              {getLanguageObject().pages.common.form.edit_button}
            </button>
            <div className="icon cursor-pointer">
              <i
                className="fa fa-trash fa-2x"
                style={{ color: "#FF0000" }}
                onClick={handleDeleteMembership}
              />
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default MembershipCard;
