export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 0, // Kuruşları gösterme
  }).format(amount);
};

export const calculatePercentageChange = (oldValue, newValue) => {
  if (oldValue === 0) {
    return newValue > 0 ? 100 : 0;
  }

  const change = ((newValue - oldValue) / oldValue) * 100;

  return change < 0 ? 0 : change.toFixed(2);
};
