export const LEVELS = {
  BEGINNER: "Başlangıç",
  INTERMEDIATE: "Orta",
  ADVANCED: "Zor",
};

export const PLATFORM = {
  ONLINE: "ONLINE",
  IN_PERSON: "IN_PERSON",
  HYBRID: "HYBRID",
};

export const LOCAL_STORAGE_KEY_CONSTANTS = {
  FORBODY_BUSINESS_LANGUAGE_KEY: "forbody_business_language_key",
};
