import React from "react";
import { Button, Modal } from "react-bootstrap";
import EmployeeForm from "./components/EmployeeForm";
import { getLanguageObject } from "../../../../../utils/language";

const SubmitEmployee = ({
  onClose,
  showSubmitEmployeeModal,
  employeeId,
  getEmployees,
}) => {
  const modalTitle = employeeId
    ? getLanguageObject().pages.employees.edit_title
    : getLanguageObject().pages.employees.add_title;

  return (
    <Modal
      className="modal fade"
      show={showSubmitEmployeeModal}
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalTitle}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={onClose}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>

        <div className="modal-body">
          <EmployeeForm
            showSubmitEmployeeModal={showSubmitEmployeeModal}
            employeeId={employeeId}
            onClose={onClose}
            getEmployees={getEmployees}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SubmitEmployee;
