import React from "react";
import "./loading.css";

const FullPageLoading = () => {
  return (
    <div id="loading" class="d-flex justify-content-center align-items-center">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default FullPageLoading;
