import { getLanguageObject } from "../../../../utils/language";

export const PURCHASE_COLUMNS = [
  {
    heading: getLanguageObject().pages.purchase.list.first_name,
  },
  {
    heading: getLanguageObject().pages.purchase.list.last_name,
  },
  {
    heading: getLanguageObject().pages.purchase.list.title,
  },

  {
    heading: getLanguageObject().pages.purchase.list.status,
  },
  {
    heading: getLanguageObject().pages.purchase.list.type,
  },
  {
    heading: getLanguageObject().pages.purchase.list.price,
  },
  {
    heading: getLanguageObject().pages.purchase.list.created_at,
  },
];
