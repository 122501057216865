import React from "react";
import { Link } from "react-router-dom";
import { getHomeRoute } from "../../utils/route";

const Error403 = ({ to }) => {
  return (
    <div className="fix-wrapper">
      <div className="container">
        {" "}
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="form-input-content text-center error-page">
              <h4>
                <i className="fa fa-times-circle text-danger" />
              </h4>
              <p>Bu sayfaya erişim yetkiniz bulunmamaktadır</p>
              <div>
                <Link className="btn btn-primary" to={to}>
                  Geri Dön
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;
