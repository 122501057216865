import React from "react";
import PageTitle from "../../../layouts/PageTitle";
import useFees from "./hooks/useFees";
import { getLanguageObject } from "../../../utils/language";

const Fees = () => {
  const { Content } = useFees();

  return (
    <>
      <PageTitle
        activeMenu={getLanguageObject().pages.common.menu.fees}
        motherMenu={getLanguageObject().pages.common.menu.home}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {getLanguageObject().pages.common.menu.fees}
          </h4>
        </div>
        <div className="card-body">{Content}</div>
      </div>
    </>
  );
};

export default Fees;
