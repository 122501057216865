import React from "react";
import { useData } from "../../hooks/useData";
import { Col } from "react-bootstrap";
import { ProgressCard } from "../CardDesign";
import { calculatePercentageChange, formatCurrency } from "../../utils/number";
import FullPageLoading from "../../components/loading/FullPageLoading";

const reportProps = {
  customersCount: {
    title: "Toplam Müşteriler",
    color: "primary",
    description: "Mevcut müşteri sayısı.",
  },
  lastMonthCustomersCount: {
    title: "Yeni Müşteriler",
    color: "danger",
    description: "Son 30 gün içinde eklenen yeni müşteri sayısı.",
  },
  totalRevenue: {
    title: "Toplam Kazanç",
    color: "info",
    description: "Tüm zamanlardaki toplam kazanç miktarı.",
  },
  lastMonthRevenue: {
    title: "Geçen Ayki Kazanç",
    color: "success",
    description: "Geçen ay elde edilen toplam kazanç.",
  },
};

const ReportCards = () => {
  const [
    customerReport = {
      customersCount: 0,
      lastMonthCustomersCount: 0,
      totalRevenue: 0,
      lastMonthRevenue: 0,
    },
    isLoading,
    hasError,
  ] = useData({
    endpoint: "purchase/report",
  });

  const reportCardsProps = [
    {
      ...reportProps.customersCount,
      number: customerReport.customersCount,
      percent: "100%",
    },
    {
      ...reportProps.lastMonthCustomersCount,
      description: `${reportProps.lastMonthRevenue.description} (%${calculatePercentageChange(customerReport.customersCount - customerReport.lastMonthCustomersCount, customerReport.lastMonthCustomersCount)} Artış)`,

      number: customerReport.lastMonthCustomersCount,
      percent: "100%",
    },
    {
      ...reportProps.totalRevenue,
      number: formatCurrency(customerReport.totalRevenue),
      percent: "100%",
    },
    {
      ...reportProps.lastMonthRevenue,
      description: `${reportProps.lastMonthRevenue.description} (%${calculatePercentageChange(customerReport.totalRevenue - customerReport.lastMonthRevenue, customerReport.lastMonthRevenue)} Artış)`,
      number: formatCurrency(customerReport.lastMonthRevenue),
      percent: "100%",
    },
  ];
  return (
    <>
      {isLoading ? <FullPageLoading /> : null}
      {reportCardsProps.map((item, index) => (
        <Col xl={"6"} xxl={"6"} sm={"6"} key={index}>
          <div className="widget-stat card">
            <ProgressCard
              title={item.title}
              number={item.number}
              percent={item.percent}
              color={item.color}
              description={item.description}
            />
          </div>
        </Col>
      ))}
    </>
  );
};

export default ReportCards;
