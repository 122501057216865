import axios from "axios";
import { getAuthorizationHeader } from "../jsx/utils/request";

const isDev = false;

export const BASE_URL = isDev
  ? "http://localhost:3001/business"
  : "https://api.forbody.app/business";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...getAuthorizationHeader() };

  return config;
});

export default axiosInstance;
