export const ROUTE_NAMES = {
  HOME: "home",
  SERVICES: "services",
  EMPLOYEES: "employees",
  CUSTOMERS: "customers",
  // CALENDAR: "calendar",
  AGENDA: "agenda",
  PURCHASES: "purchases",
  FEES: "fees",
  BUSINESS_PROFILE: "business_profile",
  HELP_CENTER: "help_center",
};

export const ROUTE_PATHS = {
  [ROUTE_NAMES.HOME]: "/",
  [ROUTE_NAMES.SERVICES]: "/services",
  [ROUTE_NAMES.EMPLOYEES]: "/employees",
  [ROUTE_NAMES.CUSTOMERS]: "/customers",
  [ROUTE_NAMES.AGENDA]: "/agenda",
  [ROUTE_NAMES.CALENDAR]: "/calendar",
  [ROUTE_NAMES.FEES]: "/fees",
  [ROUTE_NAMES.PURCHASES]: "/purchases",
  [ROUTE_NAMES.BUSINESS_PROFILE]: "/business-profile",
  [ROUTE_NAMES.HELP_CENTER]: "/help-center",
};

export const ROUTE_ICONS = {
  [ROUTE_NAMES.HOME]: "la la-home",
  [ROUTE_NAMES.SERVICES]: "la la-graduation-cap",
  [ROUTE_NAMES.AGENDA]: "la la-calendar",
  [ROUTE_NAMES.EMPLOYEES]: "la la-user",
  [ROUTE_NAMES.CUSTOMERS]: "la la-users",
  [ROUTE_NAMES.CALENDAR]: "la la-calendar",
  [ROUTE_NAMES.PURCHASES]: "la la-folder",
  [ROUTE_NAMES.FEES]: "la la-dollar",
  [ROUTE_NAMES.BUSINESS_PROFILE]: "la la-business-time",
  [ROUTE_NAMES.HELP_CENTER]: "las la-question-circle",
};
