import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useData } from "../../hooks/useData";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

const BusinessRevenue = () => {
  const [totalRevenueData = [], isLoading, hasError] = useData({
    endpoint: "purchase/total-revenue",
  });



  const reversedData = (totalRevenueData || []).reverse();

  const labels = reversedData.map((item) => item.date);
  const revenueData = reversedData.map((item) => item.revenue);
  const countData = reversedData.map((item) => item.count);

  const data = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Kazanç",
        backgroundColor: "rgba(7, 41, 77,1)",
        hoverBackgroundColor: "rgba(7, 41, 77, 1)",
        data: revenueData,
        yAxisID: "y",
        barPercentage: 0.9,
      },
      {
        type: "line",
        label: "Kullanıcı Sayısı",
        borderColor: "#ff8f16",
        backgroundColor: "#ff8f16",
        data: countData,
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "#888888",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: "#ff8f16",
        },
      },
      x: {
        ticks: {
          color: "#888888",
        },
        grid: {
          color: "#fff",
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} height={100} options={options} />
    </div>
  );
};

export default BusinessRevenue;
