import axiosInstance from "../AxiosInstance";

export function getEmployees() {
  return axiosInstance.get(`employee`);
}

export function getEmployee(id) {
  return axiosInstance.get(`employee/${id}`);
}

export function createEmployee(variables) {
  return axiosInstance.post(`employee`, variables);
}

export function updateEmployee(id, variables) {
  return axiosInstance.put(`employee/${id}`, variables);
}

export function deleteEmployee(id) {
  return axiosInstance.delete(`employee/${id}`);
}
