import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { getLanguageObject } from "../../../utils/language";
import PageTitle from "../../../layouts/PageTitle";
import useAgenda from "./hooks/useAgenda";

const Agenda = () => {
  const { getContent } = useAgenda();

  return (
    <>
      <PageTitle
        activeMenu={getLanguageObject().pages.agenda.title}
        motherMenu={getLanguageObject().pages.common.menu.home}
      />
      <div className="profile-tab">
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="ActiveServices">
            <Nav as="ul" className="nav nav-tabs">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#membership" eventKey="PassiveServices">
                  {getLanguageObject().pages.agenda.past.title}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" className="nav-item">
                <Nav.Link to="#package" eventKey="ActiveServices">
                  {getLanguageObject().pages.agenda.upcoming.title}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="card">
              <div className="card-body">
                <Tab.Content className="mt-3">
                  <Tab.Pane id="active-services" eventKey="ActiveServices">
                    {getContent("ActiveServices")}
                  </Tab.Pane>

                  <Tab.Pane id="passive-services" eventKey="PassiveServices">
                    {getContent("PassiveServices")}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default Agenda;
