import React from "react";
import { Routes, Route } from "react-router-dom";
/// Css
import "./../index.css";
import "./../chart.css";
import "./../step.css";

//Scroll To Top
import ScrollToTop from "./../layouts/ScrollToTop";

/// Pages
import LockScreen from "./../pages/error/LockScreen";
import Error400 from "./../pages/error/Error400";
import Error403 from "./../pages/error/Error403";
import Error404 from "./../pages/error/Error404";
import Error500 from "./../pages/error/Error500";
import Error503 from "./../pages/error/Error503";
import NotFound from "../pages/notFound/NotFound";
import MainLayout from "../layouts/MainLayout";

//Routes
import { AppRoutes } from "./constants/router.constants";
import useRouter from "./hooks/useRouter";
import Loading from "../components/loading/Loading";
import Onboarding from "../pages/core/onboarding/Onboarding";
import LanguageProvider from "../../context/LanguageContext";

const Router = () => {
  const { isLoading, business, hasError } = useRouter();

  if (isLoading) {
    return <Loading />;
  }

  if (!business.id) {
    return <Onboarding />;
  }

  return (
    <>
      <LanguageProvider>
        <Routes>
          <Route path="/page-lock-screen" element={<LockScreen />} />
          <Route path="/page-error-400" element={<Error400 />} />
          <Route path="/page-error-403" element={<Error403 />} />
          <Route path="/page-error-404" element={<Error404 />} />
          <Route path="/page-error-500" element={<Error500 />} />
          <Route path="/page-error-503" element={<Error503 />} />
          <Route element={<MainLayout />}>
            {AppRoutes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
        <ScrollToTop />
      </LanguageProvider>
    </>
  );
};

export default Router;
