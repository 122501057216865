export const AGENDA_COLUMNS = [
  {
    heading: "Başlık",
  },
  {
    heading: "Tip",
  },
  {
    heading: "Müşteri",
  },
  {
    heading: "Kullanım hakkı",
  },
  {
    heading: "Kullanım Sayısı",
  },
  {
    heading: "Satış tarihi",
  },
];
