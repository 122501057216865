import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { QRCodeCanvas } from "qrcode.react";
import { AppContext } from "../../../context/AppContext";
import forBodyLogo from "../../../assets/images/forBody-logo.png";

const QrModal = ({ onClose, showQrModal }) => {
  const { appBusiness } = useContext(AppContext);
  const modalTitle = appBusiness.name;

  return (
    <Modal className="modal fade" show={showQrModal} centered size="md">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalTitle}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={onClose}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>

        <div className="modal-body">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 24,
              flexDirection: "column",
              gap: 16,
            }}
          >
            <p className="text-content text-center">
              Uygulamadaki QR kod okuyucuyu kullanarak veya ilgili alana
              <strong> #{appBusiness.code}</strong> kodunu girerek kullanım
              kaydı oluşturabilirsiniz.
            </p>
            <QRCodeCanvas
              fgColor={"#312a2a"}
              value={appBusiness.code}
              size={250}
              imageSettings={{
                src: forBodyLogo,
                width: 60,
                height: 60,
                excavate: true,
              }}
              key={appBusiness.code}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QrModal;
