import React, { useMemo } from "react";
import { useData } from "../../../../hooks/useData";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import Empty from "../../../../components/empty/Empty";
import { getLanguageObject } from "../../../../utils/language";
import { getFormattedDate } from "../../../../utils/date";
import { EMPLOYEE_COLUMNS } from "../../employees/constants/employees.constants";

const useCustomers = () => {
  const [customers = [], isLoading, hasError] = useData({
    endpoint: "customer",
  });

  const columns = EMPLOYEE_COLUMNS;

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return (
        <Error
          description={getLanguageObject().pages.customers.list.message.error}
        />
      );
    }

    const isEmpty = !customers.length;

    if (isEmpty) {
      return (
        <Empty
          description={getLanguageObject().pages.customers.list.message.empty}
        />
      );
    }

    return (
      <table id="example4" className="display dataTable no-footer w-100">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr key={index}>
              <td>{customer.user.email}</td>
              <td>{customer.user.profile.firstName}</td>
              <td>{customer.user.profile.lastName}</td>
              <td>{customer.user.profile.phone}</td>
              <td>{getFormattedDate(customer.user.profile.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }, [hasError, isLoading, customers]);

  return {
    Content,
  };
};

export default useCustomers;
