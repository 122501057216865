import { useState } from "react";
import { uploadFiles } from "../actions/imageUploader";

const useImageUploader = ({ business = {}, onFilesUploaded, fileList }) => {
  const [files, setFiles] = useState(fileList);

  const handleChangeFiles = (fileList = []) => {
    return setFiles(fileList);
  };

  const handleCropCancel = () => {
    setFiles([]);
  };

  const handleUploadFiles = async (croppedImages) => {
    const uploadedFiles = await uploadFiles({
      files: croppedImages,
      businessId: business.id,
    });

    setFiles([]);

    onFilesUploaded(uploadedFiles);
  };

  const blobFiles = files.filter((file) => !!file.blobFile);

  return {
    files,
    blobFiles,
    handleChangeFiles,
    handleCropCancel,
    handleUploadFiles,
  };
};

export default useImageUploader;
