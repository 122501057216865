import { useEffect, useState } from "react";
import {
  handleUpdateBusiness,
  handleUpdateBusinessAmenities,
} from "../../../actions/business";
import { useData } from "../../../../../../hooks/useData";

const useBusinessInfo = ({ business, onBusinessUpdated }) => {
  const [amenities = { data: [] }, isLoading, hasError] = useData({
    endpoint: "/amenities",
    fetchOnce: true,
  });

  const [shouldSubmitInfo, toggleInfoSubmit] = useState(false);
  const [shouldSubmitAmenities, toggleAmenitiesSubmit] = useState(false);

  const [values, setValues] = useState({
    name: "",
    description: "",
    types: [],
    logo: "",
    amenities: [],
  });

  useEffect(() => {
    if (business) {
      setValues({
        name: business.name,
        description: business.description,
        types: business.types.map((type) => {
          return {
            label: type,
            value: type,
          };
        }),
        logo: business.logo,
        amenities: business.amenities.map(({ amenity }) => {
          return {
            label: amenity?.title,
            value: amenity?.id,
          };
        }),
      });
    }
  }, [business]);
  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleSubmitInfo = () => {
    toggleInfoSubmit(false);

    const variables = {
      name: values.name,
      description: values.description,
      types: values.types.map((type) => type.value),
      logo: values.logo,
    };

    handleUpdateBusiness({ variables, onSuccess: onBusinessUpdated });
  };

  const handleSubmitAmenities = () => {
    toggleAmenitiesSubmit(false);

    const amenities = values.amenities.map((amenity) => amenity.value);

    const variables = {
      amenities,
    };

    handleUpdateBusinessAmenities({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  const types = [
    { value: "FITNESS", label: "FITNESS" },
    { value: "SPORT", label: "SPORT" },
    { value: "WELLNESS", label: "WELLNESS" },
    { value: "SPA", label: "SPA" },
  ];

  const formattedAmenities = amenities.data.map((amenity) => ({
    label: amenity.title,
    value: amenity.id,
  }));

  const businessLogoList = values?.logo
    ? [
        {
          url: values.logo,
        },
      ]
    : [];

  return {
    values,
    types,
    handleChangeInput,
    businessLogoList,
    handleSubmitInfo,
    shouldSubmitInfo,
    toggleInfoSubmit,

    amenities: formattedAmenities,
    shouldSubmitAmenities,
    toggleAmenitiesSubmit,
    handleSubmitAmenities,
  };
};

export default useBusinessInfo;
