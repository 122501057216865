import { useEffect, useState } from "react";
import { LEVELS } from "../../../constant/global";

const useBranchSelection = ({ onSelectLevel, defaultLevel }) => {
  const [selectedLevel, setSelectedLevel] = useState({
    value: "",
    label: "",
  });

  const levels = Object.keys(LEVELS);

  const levelOptions = levels?.map((level) => ({
    value: level,
    label: LEVELS[level],
  }));

  useEffect(() => {
    if (defaultLevel) {
      const level = levelOptions.find(
        (option) => option.value === defaultLevel,
      );

      if (level) {
        setSelectedLevel(level);
      }
    }
  }, [defaultLevel]);

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel);

    onSelectLevel(selectedLevel.value);
  };

  return {
    handleSelectLevel,
    selectedLevel,
    levelOptions,
  };
};

export default useBranchSelection;
