import { useEffect, useState } from "react";
import {
  handleUpdateBusiness,
  handleUpdateBusinessInfo,
} from "../../../actions/business";

const useBusinessCoverImage = ({ business, onBusinessUpdated }) => {
  const [selectedCoverImage, setSelectedCoverImage] = useState("");

  useEffect(() => {
    if (business?.coverImage) {
      setSelectedCoverImage(business.coverImage);
    }
  }, [business?.coverImage]);

  const handleUpdateBusinessCoverImage = () => {
    const variables = {
      coverImage: selectedCoverImage,
    };

    handleUpdateBusiness({
      variables,
      onSuccess: () => {
        setSelectedCoverImage("");
        onBusinessUpdated();
      },
      onFailure: () => {},
    });
  };

  const handleChangeCoverImage = (files = []) => {
    const coverImageUrl = files[0]?.location;

    setSelectedCoverImage(coverImageUrl);
  };

  return {
    selectedCoverImage,
    handleUpdateBusinessCoverImage,
    handleChangeCoverImage,
  };
};
export default useBusinessCoverImage;
