import React from "react";
import useEmployees from "./hooks/useEmployees";
import SubmitEmployee from "./components/SubmitEmployee/SubmitEmployee";
import PageTitle from "../../../layouts/PageTitle";
import { getLanguageObject } from "../../../utils/language";

const Employees = () => {
  const { Content, submitEmployeeModalProps, handleOpenSubmitEmployeeModal } =
    useEmployees();

  return (
    <>
      <PageTitle
        activeMenu={getLanguageObject().pages.employees.title}
        motherMenu={getLanguageObject().pages.common.menu.home}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {getLanguageObject().pages.employees.title}
          </h4>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleOpenSubmitEmployeeModal();
            }}
          >
            {getLanguageObject().pages.employees.add_new_button}
          </button>
        </div>
        <div className="card-body">{Content}</div>
        <SubmitEmployee {...submitEmployeeModalProps} />
      </div>
    </>
  );
};

export default Employees;
