import React, { lazy, Suspense, useEffect, useMemo } from "react";
/// Components
import Index from "./jsx/router/index";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import { checkAutoLogin } from "./services/modules/AuthService";
import { isActivated, isAuthenticated } from "./store/selectors/AuthSelectors";

/// Style
import "rsuite/dist/rsuite-no-reset.min.css";
import "./assets/css/style.css";
import AppProvider from "./context/AppContext";
import Error403 from "./jsx/pages/error/Error403";
import { ToastContainer } from "react-toastify";

const Activation = lazy(() => import("./jsx/pages/core/activation/Activation"));

const Register = lazy(
  () => import("./jsx/pages/core/authentication/Register/Register"),
);
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("./jsx/pages/core/authentication/Login/Login")),
      500,
    );
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, []);

  let routes = useMemo(
    () =>
      props.isAuthenticated ? (
        props.isActivated ? (
          <AppProvider>
            <Index />
          </AppProvider>
        ) : (
          <Routes>
            <Route path="/activation" element={<Activation />} />
            <Route path="*" element={<Error403 to="/activation" />} />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Error403 to="/login" />} />
        </Routes>
      ),
    [props.isAuthenticated, props.isActivated],
  );

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <ToastContainer />
      {routes}
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isActivated: isActivated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
