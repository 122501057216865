import React from "react";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { getLanguageObject } from "../../../utils/language";
import usePackageLessons from "./hooks/usePackageLessons";
import SubmitPackageLesson from "./components/SubmitPackageLesson/SubmitPackageLesson";

const PackageLessons = () => {
  const { Content, handleOpenPackageLessonModal, packageLessonModalProps } =
    usePackageLessons();

  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="d-flex flex-row align-items-center gap-2">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={1}>
                  {
                    "Tek ders eklemeniz müşterilerin sizi denemesi açısından önemlidir"
                  }
                </Tooltip>
              }
            >
              <i
                className={"la la-info-circle la-2x"}
                style={{ color: "blue" }}
              />
            </OverlayTrigger>
            <h4 className="card-title">
              {getLanguageObject().pages.package_lessons.list.title}
            </h4>
          </div>

          <button
            className="btn btn-primary"
            onClick={() => {
              handleOpenPackageLessonModal();
            }}
          >
            {getLanguageObject().pages.package_lessons.list.add_new}
          </button>
        </div>
        <div className="card-body">
          <Row>{Content}</Row>
        </div>
        <SubmitPackageLesson {...packageLessonModalProps} />
      </div>
    </>
  );
};

export default PackageLessons;
