import React from "react";
import usePlatformSelection from "./hooks/usePlatformSelection";
import { PLATFORM } from "../../constant/global";
import { getLanguageObject } from "../../utils/language";

const PlatformSelection = ({ values, onSelectPlatform }) => {
  const { handleChangeInput } = usePlatformSelection({
    values,
    onSelectPlatform,
  });

  return (
    <div className="d-flex flex-row gap-3">
      <div className="form-check custom-checkbox">
        <input
          type="checkbox"
          onChange={(event) => handleChangeInput(event.target.value)}
          className="form-check-input product_order_single"
          value={PLATFORM.IN_PERSON}
          id={PLATFORM.IN_PERSON}
          defaultChecked
          checked={
            values.platform === PLATFORM.IN_PERSON ||
            values.platform === PLATFORM.HYBRID
          }
        />
        <label className="form-check-label" htmlFor={PLATFORM.IN_PERSON}>
          {getLanguageObject().pages.service.platform.in_person}
        </label>
      </div>

      <div className="form-check custom-checkbox">
        <input
          type="checkbox"
          onChange={(event) => handleChangeInput(event.target.value)}
          className="form-check-input product_order_single"
          value={PLATFORM.ONLINE}
          id={PLATFORM.ONLINE}
          checked={
            values.platform === PLATFORM.ONLINE ||
            values.platform === PLATFORM.HYBRID
          }
        />
        <label className="form-check-label" htmlFor={PLATFORM.ONLINE}>
          {getLanguageObject().pages.service.platform.online}
        </label>
      </div>
    </div>
  );
};

export default PlatformSelection;
