import {
  login,
  register,
  saveTokenInLocalStorage,
} from "../../services/modules/AuthService";

import { getHomeRoute } from "../../jsx/utils/route";
import {
  LOGOUT_ACTION,
  NAVTOGGLE,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  LOADING_TOGGLE_ACTION,
  SIGNUP_FAILED_ACTION,
} from "../constants/store.constants";
import { LOCAL_STORAGE_KEYS } from "../constants/local-storage.constants";

export function registerAction(
  { email, password, firstName, lastName, phone },
  navigate,
) {
  return (dispatch) => {
    register({ email, password, firstName, lastName, phone })
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        dispatch(loginConfirmedAction(response.data));

        return navigate("/activation");
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(navigate) {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FOR_BODY_USER);
  navigate("/login");

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction({ email, password }, navigate) {
  return (dispatch) => {
    login({ email, password })
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        dispatch(loginConfirmedAction(response.data));

        if (response.data.user.activation?.isActive) {
          return navigate(getHomeRoute());
        }

        return navigate("/activation");
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: NAVTOGGLE,
  };
};
