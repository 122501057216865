import React from "react";
import { Button, Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getLanguageObject } from "../../../../utils/language";
import { LEVELS } from "../../../../constant/global";
import { getFormattedServicePlatform } from "../../../../utils/service-platform";
import { formatCurrency } from "../../../../utils/number";

const PackageLessonCard = ({
  packageLesson,
  handleOpenPackageLessonModal,
  handleDeletePackageLesson,
}) => {
  return (
    <Col xl={3} xxl={4} lg={4} md={6} sm={6}>
      <Card>
        <div className="card-body">
          <h4>{packageLesson.name}</h4>
          <ul className="list-group mb-3 list-group-flush">
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.package_lessons.list.description} :
              </span>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={1}>{packageLesson.description}</Tooltip>}
              >
                <strong
                  className="d-inline-block text-nowrap overflow-hidden text-truncate cursor-pointer"
                  style={{ maxWidth: "150px" }}
                >
                  {packageLesson.description}
                </strong>
              </OverlayTrigger>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.package_lessons.list.duration} :
              </span>
              <strong>
                {packageLesson.duration}
                {getLanguageObject().pages.common.form.minutes}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {
                  getLanguageObject().pages.package_lessons.list
                    .participant_count
                }
                :
              </span>
              <strong>{packageLesson.participantCount}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.package_lessons.list.service_count} :
              </span>
              <strong>{packageLesson.serviceCount}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.package_lessons.list.level} :
              </span>
              <strong>
                {packageLesson.level ? LEVELS[packageLesson.level] : "-"}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.package_lessons.list.preparation} :
              </span>
              <strong>{packageLesson.preparation || "-"}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span>
                <i className="fa fa-money-bill text-primary me-2" />
                {getLanguageObject().pages.package_lessons.list.price}
              </span>
              <strong>{formatCurrency(packageLesson.price)}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">
                {getLanguageObject().pages.service.platform.title} :
              </span>
              <strong>
                {getFormattedServicePlatform(packageLesson.platform) || "-"}
              </strong>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleOpenPackageLessonModal}
            >
              {getLanguageObject().pages.package_lessons.list.edit}
            </button>

            <div className="icon cursor-pointer">
              <i
                className="fa fa-trash fa-2x"
                style={{ color: "#FF0000" }}
                onClick={handleDeletePackageLesson}
              />
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default PackageLessonCard;
