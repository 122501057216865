import { getHomeRoute } from "../../utils/route";
import Dashboard from "../../pages/core/dashboard/Dashboard2";
import { ROUTE_NAMES, ROUTE_PATHS } from "../../constant/route";
import EventManagement from "../../pages/core/dashboard/EventManagement";
import Customers from "../../pages/core/customers/Customers";
import Fees from "../../pages/core/fees/Fees";
import BusinessProfile from "../../pages/core/business-profile/BusinessProfile";
import AddProfessor from "../../pages/core/professors/AddProfessor";
import EditProfessor from "../../pages/core/professors/EditProfessor";
import ProfileProfessor from "../../pages/core/professors/ProfileProfessor";
import AddCourses from "../../pages/core/package_lessons/AddCourses";
import EditCourses from "../../pages/core/package_lessons/EditCourses";
import AboutCourses from "../../pages/core/package_lessons/AboutCourses";
import AllLibrary from "../../pages/library/AllLibrary";
import AddLibrary from "../../pages/library/AddLibrary";
import EditLibrary from "../../pages/library/EditLibrary";
import AllDepartments from "../../pages/departments/AllDepartments";
import AddDepartments from "../../pages/departments/AddDepartments";
import EditDepartments from "../../pages/departments/EditDepartments";
import AllStaff from "../../pages/staff/AllStaff";
import AddStaff from "../../pages/staff/AddStaff";
import EditStaff from "../../pages/staff/EditStaff";
import ProfileStaff from "../../pages/staff/ProfileStaff";
import AllHoliday from "../../pages/holidays/AllHoliday";
import AddHoliday from "../../pages/holidays/AddHoliday";
import EditHoliday from "../../pages/holidays/EditHoliday";
import HolidayEvent from "../../pages/holidays/HolidayEvent";
import FeesReceipt from "../../pages/core/fees/FeesReceipt";
import PostDetails from "../../pages/apps/PostDetails";
import EditProfile from "../../pages/apps/EditProfile";
import Compose from "../../pages/email/Compose/Compose";
import Inbox from "../../pages/email/Inbox/Inbox";
import Read from "../../pages/email/Read";
import Calendar from "../../pages/apps/Calendar/Calendar";
import ProductGrid from "../../pages/ecommerce/ProductGrid/ProductGrid";
import ProductList from "../../pages/ecommerce/ProductList/ProductList";
import ProductDetail from "../../pages/ecommerce/ProductGrid/ProductDetail";
import ProductOrder from "../../pages/ecommerce/ProductOrder";
import Checkout from "../../pages/ecommerce/Checkout";
import Invoice from "../../pages/ecommerce/Invoice";
import EcomCustomers from "../../pages/ecommerce/Customers";
import Content from "../../pages/cms/Content";
import Menu from "../../pages/cms/Menu";
import EmailTemplate from "../../pages/cms/EmailTemplate";
import CmsBlog from "../../pages/cms/Blog";
import ContentAdd from "../../pages/cms/ContentAdd";
import AddMail from "../../pages/cms/AddMail";
import AddBlog from "../../pages/cms/AddBlog";
import BlogCategory from "../../pages/cms/BlogCategory";
import SparklineChart from "../../pages/charts/Sparkline";
import ChartJs from "../../pages/charts/Chartjs";
import ApexChart from "../../pages/charts/apexcharts";
import RechartJs from "../../pages/charts/rechart";
import UiAccordion from "../../pages/bootstrap/Accordion";
import UiAlert from "../../pages/bootstrap/Alert";
import UiBadge from "../../pages/bootstrap/Badge";
import UiButton from "../../pages/bootstrap/Button";
import UiModal from "../../pages/bootstrap/Modal";
import UiButtonGroup from "../../pages/bootstrap/ButtonGroup";
import UiListGroup from "../../pages/bootstrap/ListGroup";
import MediaObject from "../../pages/bootstrap/MediaObject";
import UiCards from "../../pages/bootstrap/Cards";
import UiCarousel from "../../pages/bootstrap/Carousel";
import UiDropDown from "../../pages/bootstrap/DropDown";
import UiPopOver from "../../pages/bootstrap/PopOver";
import UiProgressBar from "../../pages/bootstrap/ProgressBar";
import UiTab from "../../pages/bootstrap/Tab";
import UiPagination from "../../pages/bootstrap/Pagination";
import UiTypography from "../../pages/bootstrap/Typography";
import UiGrid from "../../pages/bootstrap/Grid";
import Select2 from "../../pages/plugins/Select2/Select2";
import MainSweetAlert from "../../pages/plugins/SweetAlert";
import Toastr from "../../pages/plugins/Toastr";
import Lightgallery from "../../pages/plugins/Lightgallery";
import WidgetCard from "../../pages/widget/WidgetCard";
import WidgetChart from "../../pages/widget/WidgetChart";
import WidgetList from "../../pages/widget/WidgetList";
import Element from "../../pages/forms/Element/Element";
import Wizard from "../../pages/forms/Wizard/Wizard";
import CkEditor from "../../pages/forms/CkEditor/CkEditor";
import Pickers from "../../pages/forms/Pickers/Pickers";
import FormValidation from "../../pages/forms/FormValidation/FormValidation";
import DataTable from "../../pages/table/DataTable";
import FilteringTable from "../../pages/table/FilteringTable/FilteringTable";
import SortingTable from "../../pages/table/SortingTable/SortingTable";
import BootstrapTable from "../../pages/table/BootstrapTable";
import EmptyPage from "../../pages/core/dashboard/EmptyPage";
import React from "react";
import Purchases from "../../pages/core/purchases/Purchases";
import Services from "../../pages/core/services/Services";
import Agenda from "../../pages/core/agenda/Agenda";
import HelpCenter from "../../pages/core/help_center/HelpCenter";
import Employees from "../../pages/core/employees/Employees";

export const AppRoutes = [
  /// Dashboard
  { url: getHomeRoute(), component: <Dashboard /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.CALENDAR], component: <EventManagement /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.EMPLOYEES], component: <Employees /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.CUSTOMERS], component: <Customers /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.SERVICES], component: <Services /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.AGENDA], component: <Agenda /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.FEES], component: <Fees /> },
  { url: ROUTE_PATHS[ROUTE_NAMES.PURCHASES], component: <Purchases /> },
  {
    url: ROUTE_PATHS[ROUTE_NAMES.BUSINESS_PROFILE],
    component: <BusinessProfile />,
  },

  {
    url: ROUTE_PATHS[ROUTE_NAMES.HELP_CENTER],
    component: <HelpCenter />,
  },

  //Professors
  { url: "add-professor", component: <AddProfessor /> },
  { url: "edit-professor", component: <EditProfessor /> },
  { url: "professor-profile", component: <ProfileProfessor /> },

  //Course

  { url: "add-courses", component: <AddCourses /> },
  { url: "edit-courses", component: <EditCourses /> },
  { url: "about-courses", component: <AboutCourses /> },

  //Library
  { url: "all-library", component: <AllLibrary /> },
  { url: "Add-library", component: <AddLibrary /> },
  { url: "edit-library", component: <EditLibrary /> },

  //Department
  { url: "All-departments", component: <AllDepartments /> },
  { url: "add-departments", component: <AddDepartments /> },
  { url: "edit-departments", component: <EditDepartments /> },

  //Staff
  { url: "all-staff", component: <AllStaff /> },
  { url: "add-staff", component: <AddStaff /> },
  { url: "edit-staff", component: <EditStaff /> },
  { url: "staff-profile", component: <ProfileStaff /> },

  //Holiday
  { url: "all-holiday", component: <AllHoliday /> },
  { url: "add-holiday", component: <AddHoliday /> },
  { url: "edit-holiday", component: <EditHoliday /> },
  { url: "holiday-calendar", component: <HolidayEvent /> },

  //fees

  { url: "fees-receipt", component: <FeesReceipt /> },

  /// Apps
  { url: "app-profile", component: <BusinessProfile /> },
  { url: "post-details", component: <PostDetails /> },
  { url: "edit-profile", component: <EditProfile /> },
  { url: "email-compose", component: <Compose /> },
  { url: "email-inbox", component: <Inbox /> },
  { url: "email-read", component: <Read /> },
  { url: "app-calender", component: <Calendar /> },
  /// Shop
  { url: "ecom-product-grid", component: <ProductGrid /> },
  { url: "ecom-product-list", component: <ProductList /> },
  { url: "ecom-product-detail", component: <ProductDetail /> },
  { url: "ecom-product-order", component: <ProductOrder /> },
  { url: "ecom-checkout", component: <Checkout /> },
  { url: "ecom-invoice", component: <Invoice /> },
  { url: "ecom-customers", component: <EcomCustomers /> },

  ///Cms
  { url: "content", component: <Content /> },
  { url: "menu", component: <Menu /> },
  { url: "email-template", component: <EmailTemplate /> },
  { url: "blog", component: <CmsBlog /> },
  { url: "content-add", component: <ContentAdd /> },
  { url: "add-email", component: <AddMail /> },
  { url: "add-blog", component: <AddBlog /> },
  { url: "blog-category", component: <BlogCategory /> },

  /// Chart
  { url: "chart-sparkline", component: <SparklineChart /> },
  { url: "chart-chartjs", component: <ChartJs /> },
  { url: "chart-apexchart", component: <ApexChart /> },
  { url: "chart-rechart", component: <RechartJs /> },

  /// Bootstrap
  { url: "ui-accordion", component: <UiAccordion /> },
  { url: "ui-alert", component: <UiAlert /> },
  { url: "ui-badge", component: <UiBadge /> },
  { url: "ui-button", component: <UiButton /> },
  { url: "ui-modal", component: <UiModal /> },
  { url: "ui-button-group", component: <UiButtonGroup /> },
  { url: "ui-list-group", component: <UiListGroup /> },
  { url: "ui-media-object", component: <MediaObject /> },
  { url: "ui-card", component: <UiCards /> },
  { url: "ui-carousel", component: <UiCarousel /> },
  { url: "ui-dropdown", component: <UiDropDown /> },
  { url: "ui-popover", component: <UiPopOver /> },
  { url: "ui-progressbar", component: <UiProgressBar /> },
  { url: "ui-tab", component: <UiTab /> },
  { url: "ui-pagination", component: <UiPagination /> },
  { url: "ui-typography", component: <UiTypography /> },
  { url: "ui-grid", component: <UiGrid /> },

  /// Plugin

  { url: "uc-select2", component: <Select2 /> },
  { url: "uc-sweetalert", component: <MainSweetAlert /> },
  { url: "uc-toastr", component: <Toastr /> },
  // { url: "map-jqvmap", component: <JqvMap /> },
  { url: "uc-lightgallery", component: <Lightgallery /> },

  /// Widget
  { url: "widget-card", component: <WidgetCard /> },
  { url: "widget-chart", component: <WidgetChart /> },
  { url: "widget-list", component: <WidgetList /> },

  // Form
  { url: "form-element", component: <Element /> },
  { url: "form-wizard", component: <Wizard /> },
  { url: "form-ckeditor", component: <CkEditor /> },
  { url: "form-pickers", component: <Pickers /> },
  { url: "form-validation", component: <FormValidation /> },

  /// table
  { url: "table-datatable-basic", component: <DataTable /> },
  { url: "table-filtering", component: <FilteringTable /> },
  { url: "table-sorting", component: <SortingTable /> },
  { url: "table-bootstrap-basic", component: <BootstrapTable /> },

  /// pages
  { url: "empty", component: <EmptyPage /> },
];
