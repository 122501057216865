import React from "react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import { getLanguageObject } from "../../../../../utils/language";
import FileUploadIcon from "@rsuite/icons/FileUpload";
import { UploadButton } from "./BusinessCoverImage.styled";
import useBusinessCoverImage from "./hooks/useBusinessCoverImage";
import ImageUploader from "../../../../../components/imageUploader/ImageUploader";

const BusinessCoverImage = ({ business, onBusinessUpdated, onInit }) => {
  const {
    handleChangeCoverImage,
    handleUpdateBusinessCoverImage,
    selectedCoverImage,
  } = useBusinessCoverImage({
    business,
    onBusinessUpdated,
  });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <div className="flex flex-column">
            <h5 className="text-primary ">
              {
                getLanguageObject().pages.business_profile.business_images
                  .cover_image_title
              }
            </h5>
            <small>
              {
                getLanguageObject().pages.business_profile.business_images
                  .cover_image_description
              }
            </small>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-4">
            <ImageUploader
              business={business}
              multiple={false}
              onFilesUploaded={handleChangeCoverImage}
            >
              <button style={UploadButton}>
                <FileUploadIcon className="fs-3" />
              </button>
            </ImageUploader>

            <button
              className="btn btn-primary"
              onClick={handleUpdateBusinessCoverImage}
              disabled={!selectedCoverImage}
            >
              {
                getLanguageObject().pages.business_profile.business_images
                  .upload_button
              }
            </button>
          </div>
        </div>

        <div className="card-body pt-3">
          <div className="profile-interest ">
            {selectedCoverImage ? (
              <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                elementClassNames="row sp4"
              >
                <div
                  data-src={selectedCoverImage}
                  className="col-lg-4 col-xl-4 col-sm-4 col-6 int-col mb-1"
                >
                  <img
                    src={selectedCoverImage}
                    style={{ width: "100%" }}
                    alt="gallery"
                  />
                </div>
              </LightGallery>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCoverImage;
