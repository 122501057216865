import {
  createBusinessInfoLink,
  deleteBusinessInfoLink,
} from "../../../actions/business";
import { useEffect, useState } from "react";
import { getDefaultBusinessLink } from "../constants/business-links.constants";
import {
  getBusinessLinks,
  getFormattedTags,
} from "../helpers/business-links.helpers";

const useBusinessLinks = ({ business, onBusinessUpdated }) => {
  const [businessLinks, setBusinessLinks] = useState([]);
  const [showCreateButton, toggleCreateButton] = useState(false);

  const linkNames = getFormattedTags();

  const handleAddBusinessLink = () => {
    setBusinessLinks([...businessLinks, getDefaultBusinessLink()]);
  };

  useEffect(() => {
    if (business?.info?.links?.length) {
      const links = getBusinessLinks(business?.info?.links);

      setBusinessLinks([...links]);
    }
  }, [business?.info?.links]);

  const handleChangeBusinessLink = (linkId, name, input) => {
    const links = businessLinks.map((link) => {
      if (link.id === linkId) {
        link[name] = input;

        if (name === "name") {
          const linkName = linkNames.find(
            (linkName) => linkName.value === input.value,
          );

          if (!link.url.startsWith(linkName.format)) {
            link["url"] = linkName.format;
          }
        }
      }

      return link;
    });

    setBusinessLinks([...links]);
  };

  const handleDeleteBusinessLink = async (linkId) => {
    const links = businessLinks.filter((link) => link.id !== linkId);

    setBusinessLinks([...links]);

    if (linkId) {
      const variables = {
        linkId,
      };

      await deleteBusinessInfoLink(variables);
    }
  };

  const handleCreateBusinessLink = () => {
    const lastLink = businessLinks[businessLinks.length - 1];

    const variables = {
      name: lastLink.name.value,
      url: lastLink.url,
    };

    createBusinessInfoLink(variables);

    toggleCreateButton();

    onBusinessUpdated();
  };

  useEffect(() => {
    const addedLinks = businessLinks.filter((link) => !link.id);

    toggleCreateButton(!!addedLinks.length);
  }, [businessLinks.length]);

  return {
    businessLinks,
    linkNames,
    showCreateButton,
    handleAddBusinessLink,
    handleChangeBusinessLink,
    handleCreateBusinessLink,
    handleDeleteBusinessLink,
  };
};

export default useBusinessLinks;
