import TR from "../translation/locales/tr/translation.json";
import EN from "../translation/locales/en/translation.json";
import { LOCAL_STORAGE_KEY_CONSTANTS } from "../constant/global";

export const getLanguageObject = () => {
  const language = localStorage.getItem(
    LOCAL_STORAGE_KEY_CONSTANTS.FORBODY_BUSINESS_LANGUAGE_KEY,
  );

  if (language === "en") {
    return EN;
  }
  return TR;
};
