import React, { createContext, useState } from "react";
import QrModal from "../jsx/components/qrModal/QrModal";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [appBusiness, setAppBusiness] = useState({});
  const [showQrModal, toggleQrModal] = useState(false);

  const openQrModal = () => {
    if (appBusiness.id) {
      toggleQrModal(true);
    }
  };

  const closeQrModal = () => {
    toggleQrModal(false);
  };

  const updateAppBusiness = (authenticatedBusiness) => {
    setAppBusiness(authenticatedBusiness);
  };

  return (
    <AppContext.Provider
      value={{ openQrModal, updateAppBusiness, appBusiness }}
    >
      <QrModal showQrModal={showQrModal} onClose={closeQrModal} />
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
