import React, { useEffect, useMemo, useState } from "react";
import Cropper from "react-easy-crop";
import { Button, Modal } from "react-bootstrap";

const ImageCropper = ({
  files = [],
  handleCropCancel,
  handleUploadFiles,
  cropSize,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cropState, setCropState] = useState([]);

  const handleNext = async () => {
    if (currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const croppedImages = await getCroppedImages();

      handleUploadFiles(croppedImages);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getImages = useMemo(() => {
    return files.map((file) => URL.createObjectURL(file.blobFile));
  }, [files]);

  useEffect(() => {
    if (files.length) {
      const crops = files.map(() => ({
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: null,
        rotation: 0,
      }));

      setCropState(crops);
    }
  }, [files.length]);

  const handleCropChange = (index, crop) => {
    const updatedState = [...cropState];
    updatedState[index].crop = crop;
    setCropState(updatedState);
  };

  const handleRotationChange = (index, rotation) => {
    const updatedState = [...cropState];
    updatedState[index].rotation = rotation;
    setCropState(updatedState);
  };

  const handleZoomChange = (index, zoom) => {
    const updatedState = [...cropState];
    updatedState[index].zoom = zoom;
    setCropState(updatedState);
  };

  const handleCropComplete = (index, croppedArea, croppedAreaPixels) => {
    const updatedState = [...cropState];
    updatedState[index].croppedAreaPixels = croppedAreaPixels;

    setCropState(updatedState);
  };

  const getCroppedImages = async () => {
    const croppedImagesPromises = files.map((file, index) => {
      return getCroppedImage(
        URL.createObjectURL(file.blobFile),
        cropState[index].croppedAreaPixels,
      ).then((croppedBlob) => {
        const croppedFile = new File([croppedBlob], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });

        return croppedFile;
      });
    });

    const croppedImages = await Promise.all(croppedImagesPromises);

    return croppedImages;
  };

  const getCroppedImage = async (imageSrc, crop) => {
    const image = await loadImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x,
      crop.y,
      crop.width,
      crop.height,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas to blob failed"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  return (
    <Modal show={!!files.length} onHide={handleCropCancel} centered size="lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Resim Boyutlandır</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={handleCropCancel}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>
        <div
          className="modal-body d-flex  bg-white justify-content-center align-items-center flex-column"
          style={{ height: "50vh" }}
        >
          {cropState.length ? (
            <Cropper
              zoomWithScroll={true}
              image={getImages[currentIndex]}
              crop={cropState[currentIndex].crop}
              zoom={cropState[currentIndex].zoom}
              rotation={cropState[currentIndex].rotation}
              aspect={4 / 3}
              showGrid={false}
              objectFit="cover"
              cropSize={cropSize}
              minZoom={0.1}
              zoomSpeed={0.1}
              onRotationChange={(rotation) =>
                handleCropChange(currentIndex, rotation)
              }
              onCropChange={(crop) => handleCropChange(currentIndex, crop)}
              onZoomChange={(zoom) => handleZoomChange(currentIndex, zoom)}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                handleCropComplete(currentIndex, croppedArea, croppedAreaPixels)
              }
            />
          ) : null}
        </div>
        <div className="modal-footer justify-content-between">
          <input
            type="button"
            value={"Geri"}
            onClick={handleBack}
            disabled={currentIndex === 0}
            className="btn btn-primary w-25"
          />

          <input
            type="button"
            value={currentIndex < files.length - 1 ? "Sonraki Resim" : "Bitir"}
            onClick={handleNext}
            className="btn btn-primary w-25"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ImageCropper;
