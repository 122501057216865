import { useEffect, useState } from "react";
import { handleUpdateBusinessLocation } from "../../../actions/business";

const useBusinessLocation = ({ business, onBusinessUpdated }) => {
  const [values, setValues] = useState({
    lng: 0,
    lat: 0,
    label: "",
  });

  useEffect(() => {
    if (business) {
      setValues({
        lng: business?.location?.coordinates[0],
        lat: business?.location?.coordinates[1],
        label: business.info.address,
      });
    }
  }, [business]);

  const handleSubmit = () => {
    const variables = {
      lat: values.lat,
      lng: values.lng,
      address: values.label,
    };

    handleUpdateBusinessLocation({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  const handleChangeInput = (value) => {
    setValues(value);
  };

  return {
    values,
    handleSubmit,
    handleChangeInput,
  };
};

export default useBusinessLocation;
